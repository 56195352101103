@use "sass:map";
@import '@amzn/ring-common-style-library/lib/colors';

// Colors
$neutral-50: map.get($colors, neutral-50);
$neutral-300: map.get($colors, neutral-300);
$neutral-500: map.get($colors, neutral-500);
$neutral-900: map.get($colors, neutral-900);
$primary-100: map.get($colors, primary-100);
$primary-500: map.get($colors, primary-500);
$primary-600: map.get($colors, primary-600);
$primary-press: map.get($colors, primary-700);
$secondary-500: map.get($colors, secondary-500);
$tertiary-300: map.get($colors, tertiary-300);
$tertiary-500:  map.get($colors, tertiary-500);

$white: map.get($colors, white-900);
$black: map.get($colors, black-900);
$orange: map.get($colors, secondary-500);

// Fonts
$equip: 'Equip', Helvetica, Arial, sans-serif;

// Breakpoints
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

// Misc.
$nav-height-mobile: 64px;
$nav-height-desktop: 68px;
$mobile-panel-size: 375px;
$drawer-height: 80px;
$drawer-box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.15);
$btn-radius: 8px;
$btn-submenu-radius: 4px;
$z-index-nav-menu: 1005;
$z-index-top: 1004;
$z-index-base: 1003;
$z-index-overlay: 1002;
$nav-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

:export {
  NEUTRAL_500: $neutral-500;
}

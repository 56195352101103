@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.cta-drawer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 16px 24px;
  background-color: vars.$white;

  @include mixins.media-md {
    width: vars.$mobile-panel-size;
  }

  @include mixins.media-lg {
    display: none;
  }

  a {
    @include mixins.typography(body-large-bold);
    display: block;
    margin: 0 auto;
    padding: 10px 16px;
    color: vars.$white;
    border-radius: vars.$btn-radius;
    text-align: center;
    text-decoration: none;

    &:nth-child(1) {
      background-color: vars.$neutral-900;
    }
    
    &:nth-child(2) {
      margin-top: 12px;
      color: vars.$neutral-900;
      border: 1px solid vars.$neutral-900;
    }
    
    &:nth-child(1):active {
      background-color: vars.$neutral-500;
    }

    &:nth-child(2):active {
      background-color: vars.$neutral-300;
    }
  }
}

:export {
  var-drawer-box-shadow: vars.$drawer-box-shadow;
}

@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.panel {
  flex: 1;
  display: none;

  &.visible {
    display: block;
  }

  .panel-content {
    > * {
      margin-bottom: 24px;
    }

    @include mixins.media-lg {
      padding: 24px;
      flex: 1 0 auto;
    }

    .panel-header {
      display: flex;
      gap: 8px;
      color: vars.$neutral-500;
      @include mixins.typography(body-normal-medium);

      a {
        color: vars.$primary-600;
      }
    }
  }
}

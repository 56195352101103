@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.submenu {
  display: flex;

  @include mixins.media-lg {
    min-height: 560px;
  }

  .menu-items {
    flex: 1 0 auto;

    @include mixins.media-lg {
      flex: 1 0 27%;
      max-width: 228px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: vars.$neutral-50;
      padding-left: 40px;
    }

    @include mixins.media-xl {
      max-width: 270px;
    }

    ul {
      display: flex;
      flex-flow: column nowrap;
      margin: 0;
      padding: 16px 0 10px 0;
      list-style: none;
      gap: 4px;

      &:first-child {
        border-bottom: solid vars.$tertiary-500 1px;
      }

      @include mixins.media-lg {
        padding-bottom: 0;

        &:first-child {
          border-bottom: none;
        }
      }

      @include mixins.media-lg {
        padding: 24px 16px 32px 0;
      }

      li {
        margin: 0; // RW reset

        &.only-desktop {
          display: none;

          @include mixins.media-lg {
            display: initial;
          }
        }

        svg {
          width: 20px;

          @include mixins.media-lg {
            display: initial;
            width: 16px;
          }
        }

        a,
        button {
          background-color: transparent;
          border-radius: vars.$btn-submenu-radius;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 16px;
          text-decoration: none;
          width: 100%;
          color: vars.$neutral-500;
          padding: 12px 24px;
          @include mixins.typography(body-large-medium);

          @include mixins.media-md {
            gap: 8px;
          }

          @include mixins.media-lg {
            padding: 4px 12px 4px 12px;
            @include mixins.typography(body-normal-regular);

            &.active-btn {
              color: vars.$primary-600;
              background-color: vars.$primary-100;

              svg {
                --gn-svg-fill: #{vars.$primary-600};
              }

              &:active {
                color: vars.$primary-press;

                svg {
                  --gn-svg-fill: #{vars.$primary-press};
                }
              }
            }
          }

          .chevron-wrapper {
            display: flex;
            margin: auto;
            margin-right: 0;

            svg {
              display: initial;
              width: 24px;
              height: 24px;

              @include mixins.media-lg {
                display: none;
              }
            }
          }

          &.link {
            color: vars.$neutral-500;
            text-decoration: none;
            @include mixins.typography(body-normal-regular);

            @include mixins.media-lg {
              @include mixins.typography(body-small-regular);
            }

            &:hover {
              color: vars.$primary-600;

              svg {
                --gn-svg-fill: #{vars.$primary-600};
              }
            }

            &:active * {
              color: vars.$primary-press;

              svg {
                --gn-svg-fill: #{vars.$primary-press};
              }
            }
          }

          .blue-tag {
            color: vars.$primary-600;
          }
        }
      }
    }
  }

  [class*='panel-content'] {
    @include mixins.media-lg {
      padding: 24px 40px 0px;
      flex: 1 0 auto;
    }
  }
}

@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.nav-container {
  display: flex;
  align-items: center;
  padding: 0 24px 0 0;
  background-color: vars.$white;
  position: relative;
  z-index: vars.$z-index-base;
  min-height: vars.$nav-height-mobile;
  border-bottom: 1px solid vars.$tertiary-500;

  @include mixins.media-lg {
    border: none;
    padding: 0 50px;
  }

  .login {
    @include mixins.typography(body-large-medium);
    color: vars.$neutral-500;
    text-decoration: none;
  }

  .icon-group {
    display: flex;
    align-items: center;
    gap: 24px;

    .icon-container {
      @include mixins.typography(footnote-xsmall-medium);
      @include mixins.icon-container;

      padding: 14px 0 9px;
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom: 4px solid vars.$primary-500;
      }

      &.lg-only {
        display: none;

        @include mixins.media-lg {
          display: flex;
        }
      }

      p {
        @include mixins.typography(footnote-xsmall-medium);
        margin: 0;
        text-align: center;
        color: vars.$neutral-500;
      }

      &.cart-btn {
        padding: 14px 0 9px;
      }

      .gn-qty {
        display: flex;
        justify-content: center;
        align-items: center;
        color: vars.$white;
        background: vars.$orange;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        text-align: center;
        position: absolute;
        right: -8px;
        top: 4px;

        &:before {
          content: attr(data-qty);
        }

        &[data-qty='0'] {
          display: none;
        }
      }
    }
  }

  ul {
    // override user agent styles
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 40px;
    margin-inline-end: 0;
    padding-inline-start: 0;
    
    display: none;
    list-style: none;

    > * + * {
      margin-inline-start: 40px;
    }

    @include mixins.media-lg {
      display: flex;
    }

    .nav-item {
      button {
        @include mixins.typography(body-large-medium);
        color: vars.$neutral-500;
        padding: 20px 0;
        background: transparent;
        border-bottom: 4px solid transparent;

        &.active,
        &:hover,
        &:focus {
          border-bottom: 4px solid vars.$primary-500;
        }
      }
    }
  }

  .flex-spacer {
    flex-grow: 1;
  }
}

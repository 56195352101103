@use '../../styles/variables' as vars;

.gn-btn {
  border: none;
  background: transparent;
  padding: 0;
  min-width: 0;
  margin: 0;
  transition: all 0s ease 0s;
  border-radius: 0;
  background-color: vars.$white;
}

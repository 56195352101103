@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.mobile-nav-dropdown-container {
  width: 100%;
  height: 100%;
  z-index: vars.$z-index-nav-menu;
  display: none;

  &.visible {
    display: block;
  }

  @include mixins.media-md {
    position: fixed;
    top: 0;
    left: 0;
  }

  .mobile-nav-dropdown {
    background-color: vars.$white;
    overflow: scroll;
    max-height: calc(100% - vars.$drawer-height * 2 - vars.$nav-height-mobile);

    @include mixins.media-md {
      height: 100%;
      max-height: initial;
      width: vars.$mobile-panel-size;
    }

    ul {
      padding: 0;
      padding-top: 12px;
      border-top: 1px solid vars.$tertiary-500;
      list-style: none;
      margin: 0;

      li {
        margin: 0;

        svg {
          margin-left: auto;

          &:first-child {
            margin: 0 16px 0 0;
          }
        }

        button {
          @include mixins.typography(body-large-medium);
          display: flex;
          width: 100%;
          text-align: left;
          align-items: center;
          justify-content: flex-start;
          padding: 12px 24px;
          color: vars.$neutral-500;
          background: transparent;

          &:hover,
          &:focus {
            background: transparent;
          }

          &:active {
            color: vars.$primary-press;
            background-color: vars.$primary-100;

            svg {
              --gn-svg-fill: #{vars.$primary-press};
            }
          }
        }
      }
    }
  }
}

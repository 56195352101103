@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.product-row {
  display: flex;
  gap: 12px;

  .product-block {
    display: flex;
    flex-flow: column;
    border: 1px solid vars.$tertiary-500;
    border-radius: vars.$btn-radius;
    width: 25%;
    text-align: center;
    padding: 16px;
    position: relative;
    text-decoration: none;
    color: vars.$neutral-500;
    @include mixins.typography(body-normal-medium);

    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }

    .badge {
      margin: auto;
      margin-left: 0;
      display: inline-block;
      border: 1px solid vars.$primary-500;
      padding: 0 8px;
      border-radius: 40px;
      @include mixins.desktop-mobile-badge;

      &.blue {
        border: 1px solid vars.$primary-500;
        color: vars.$primary-500;
      }

      &.orange {
        border: 1px solid vars.$secondary-500;
        color: vars.$secondary-500;
      }
    }

    .spacer {
      flex: 1;
    }
  }
}

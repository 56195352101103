@use 'styles/variables' as vars;
@use 'styles/mixins';
@import '@amzn/ring-common-style-library/lib/fonts';

:root {
  --gn-svg-fill: #{vars.$neutral-500};
}

* {
  font-family: vars.$equip;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  body {
    margin: 0;

    header {
      float: none;
    }

    a,
    button {
      cursor: pointer;
    }

    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px 10px;
      background-color: vars.$white;
      position: relative;
      z-index: vars.$z-index-base;
      box-shadow: vars.$nav-shadow;

      @media screen and (min-width: vars.$breakpoint-lg) {
        padding: 0 50px;
        min-height: vars.$nav-height-desktop;
      }

      .header-logo-link {
        line-height: 0;

        svg {
          height: 32px;
          width: 50px;

          @media screen and (min-width: vars.$breakpoint-lg) {
            height: 40px;
            width: 64px;
          }
        }
      }

      .wrapper-right {
        display: flex;
        gap: 35px;
        align-items: center;

        .login-link {
          @include mixins.typography(body-large-medium);
        }

        a {
          text-decoration: none;
          color: vars.$neutral-500;
        }

        .icon-container {
          @include mixins.typography(footnote-xsmall-medium);
          @include mixins.icon-container;
        }
      }
    }
  }
}

@use 'variables' as vars;
@import '@amzn/ring-common-style-library/lib/typography';

@mixin desktop-mobile-badge {
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.5px;
}

@mixin flex($justification: flex-start, $alignment: start, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
}

@mixin sm-only {
  @media screen and (max-width: calc(vars.$breakpoint-md - 1px)) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: calc(vars.$breakpoint-lg - 1px)) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: vars.$breakpoint-md) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: vars.$breakpoint-lg) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: vars.$breakpoint-xl) {
    @content;
  }
}

// Reusable rules
@mixin icon-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  background: transparent;
}

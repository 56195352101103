@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.blocks {
  @include mixins.media-lg {
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 14px));
    padding: 16px;
    gap: 24px;
  }

  @include mixins.media-xl {
    grid-template-columns: repeat(4, calc(25% - 20px));
  }

  .block {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 26px;
    text-decoration: none;

    @include mixins.media-md {
      padding: 16px 26px;
    }

    @include mixins.media-lg {
      border: none;
      align-items: flex-start;
      border-radius: 8px;
      background-color: vars.$tertiary-300;
      min-height: 287px;

      &:hover {
        .link-container {
          span {
            color: vars.$primary-600;
          }

          svg {
            --gn-svg-fill: #{vars.$primary-600};
          }
        }
      }

      &:active {
        .link-container {
          span {
            color: vars.$primary-press;
          }

          svg {
            --gn-svg-fill: #{vars.$primary-press};
          }
        }
      }
    }

    &:last-child {
      @include mixins.media-lg {
        border-bottom: none;
      }
    }

    .left-container {
      display: flex;

      @include mixins.media-lg {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
      }

      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .main-icon {
        display: inherit;
        margin-right: 20px;
        margin-bottom: 14px;

        svg {
          @include mixins.media-lg {
            height: 32px;
            width: 32px;
          }
        }
      }

      h2 {
        @include mixins.typography(body-large-medium);
        color: vars.$neutral-500;
        margin: 0;

        @include mixins.media-lg {
          margin-bottom: 4px;
        }
      }

      p {
        @include mixins.typography(body-small-light);
        color: vars.$neutral-300;
        margin: 2px 0 0;

        @include mixins.media-lg {
          @include mixins.typography(body-normal-light);
          margin-bottom: 12px;
        }
      }

      .link-container {
        @include mixins.typography(body-normal-medium);
        text-decoration: none;
        display: none;
        color: vars.$neutral-500;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }

        @include mixins.media-lg {
          display: flex;
        }
      }
    }

    @include mixins.mobile-only {
      &.current {
        background-color: vars.$primary-100;

        p,
        h2 {
          color: vars.$primary-600;
        }

        .left-container {
          svg {
            --gn-svg-fill: #{vars.$primary-600};
          }
        }
      }
    }
  }
}

@use '../../styles/variables' as vars;
@use '../../styles/mixins';

.gn-main {
  background-color: vars.$white;
  text-rendering: initial;
  -moz-osx-font-smoothing: initial;
  box-shadow: vars.$nav-shadow;
  position: relative;
  z-index: vars.$z-index-top;
  height: vars.$nav-height-mobile;

  &.is-open {
    height: 100vh;

    @include mixins.sm-only {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  @include mixins.media-md {
    position: absolute;
    width: 100%;
    background-color: transparent;
  }

  @include mixins.media-lg {
    height: vars.$nav-height-desktop;
    position: relative;

    &.is-open {
      height: auto;
    }
  }
}

nav {
  height: vars.$nav-height-mobile;
  @include mixins.media-lg {
    height: vars.$nav-height-desktop;
  }

  .bg-overlay {
    display: none;
    position: fixed;
    background-color: vars.$black;
    top: 0;
    left: vars.$mobile-panel-size;
    width: 100%;
    height: 200vh;
    z-index: vars.$z-index-top;
    opacity: 0.8;

    &.visible {
      @include mixins.media-md {
        display: block;
      }
      @include mixins.media-lg {
        position: absolute;
        left: 0;
        z-index: vars.$z-index-overlay;
      }
    }    
  }
}

body {
  &.gn-open {
    overflow: hidden;
    position: fixed;
    width: 100%;

    @include mixins.media-lg {
      position: initial;
      overflow: auto;
      width: initial;
    }
  }
}

@use '../../styles/mixins';
@use '../../styles/variables' as vars;

@keyframes slide {
  from {
    left: 100vw;
  }

  to {
    left: 0;
  }
}

@keyframes slide-reverse {
  from {
    left: 0;
  }

  to {
    left: 100vw;
  }
}

.menu-holder {
  display: none;

  &.visible {
    display: block;
  }

  @include mixins.media-lg {
    height: 0;
    overflow: visible;
  }

  .menu {
    width: 100%;
    height: 100%;
    background-color: vars.$white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: vars.$z-index-nav-menu;
    box-sizing: border-box;
    animation-name: slide;
    animation-duration: 0.25s;
    animation-delay: 0s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    &.closing {
      animation-name: slide-reverse;
    }

    @include mixins.media-md {
      width: vars.$mobile-panel-size;
      animation: none;
    }

    @include mixins.media-lg {
      position: relative;
      width: auto;
      height: auto;
      border-top: 1px solid vars.$tertiary-500;
    }

    .upper-container {
      @include mixins.typography(body-normal-medium);
      display: flex;
      align-items: center;
      justify-content: center;
      height: vars.$nav-height-mobile;
      color: vars.$neutral-500;
      padding: 0 24px;
      border-bottom: 1px solid vars.$tertiary-500;

      @include mixins.media-lg {
        display: none;
      }

      button {
        background-color: white;
        position: absolute;
        left: 24px;
        line-height: 0;
      }
    }

    .lower-container {
      max-height: calc(100vh - vars.$nav-height-mobile);
      overflow: scroll;
      @include mixins.media-lg {
        max-height: initial;
        overflow: auto;
      }
    }

    .accessibility-end {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }
}

:export {
  var-upper-container-height: vars.$nav-height-mobile;
  var-drawer-height: vars.$drawer-height;
}

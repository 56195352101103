@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.card-grid {
  display: block;

  @include mixins.media-lg {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    max-width: 1024px;
    gap: 24px;
  }

  @include mixins.media-xl {
    grid-template-columns: repeat(4, 24%);
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;

    @include mixins.media-lg {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0 24px 0;
      min-height: 460px;
      background-color: vars.$tertiary-300;
      border-radius: 8px;
      overflow: hidden;

      &:hover {
        cursor: pointer;

        .arrow-link {
          color: vars.$primary-600;

          svg {
            --gn-svg-fill: #{vars.$primary-600};
          }
        }

        .image-wrapper img {
          transform: scale(115%);
          transition: transform 0.3s linear;
        }
      }

      &:active {
        .arrow-link {
          color: vars.$primary-press;

          svg {
            --gn-svg-fill: #{vars.$primary-press};
          }
        }
      }
    }

    &.only-desktop {
      display: none;

      @include mixins.media-lg {
        display: flex;
      }
    }

    .card-content {
      display: flex;
      align-items: center;

      @include mixins.media-lg {
        width: 100%;
        display: block;

        > div {
          padding: 0 16px;
        }
      }

      .title {
        @include mixins.typography(body-large-medium);
        color: vars.$neutral-500;
      }

      p {
        @include mixins.typography(body-small-light);
        color: vars.$neutral-300;
        margin: 2px 0 0 0;

        @include mixins.media-lg {
          @include mixins.typography(body-normal-light);
          margin: 4px 0 24px 0;
        }
      }

      .image-wrapper {
        @include mixins.media-lg {
          padding-top: 16px;
          width: 100%;
          max-height: 154px;
          display: flex;
          justify-content: center;
          margin-bottom: 16px;
          overflow: hidden;

          &.big-image {
            padding: 0;

            img {
              max-width: none;
              max-height: none;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }

        img {
          width: 54px;
          height: 54px;
          margin-right: 16px;

          @include mixins.media-lg {
            width: 100%;
            height: 100%;
            max-width: 146px;
            max-height: 146px;
            margin: 0;
            transition: transform 0.3s linear;
          }
        }
      }

      .featured-list {
        display: none;
        padding: 0;
        margin: 0;
        list-style: none;

        @include mixins.media-lg {
          display: block;
          padding: 0 16px;
        }

        li {
          margin-left: 0;
          margin-bottom: 8px;

          &:nth-child(1) {
            @include mixins.typography(body-normal-medium);
            color: vars.$neutral-300;
          }

          a {
            @include mixins.typography(body-normal-regular);
            color: vars.$neutral-500;
            text-decoration: none;

            &:hover {
              color: vars.$primary-600;
            }

            &:active {
              color: vars.$primary-press;
            }
          }
        }
      }
    }

    .arrow-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      @include mixins.typography(body-normal-medium);
      color: vars.$neutral-500;

      @include mixins.media-lg {
        padding: 0 16px;
      }

      span {
        display: none;

        @include mixins.media-lg {
          display: initial;
        }
      }

      svg {
        display: none;

        @include mixins.media-lg {
          display: initial;
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        color: vars.$primary-600;

        svg {
          --gn-svg-fill: #{vars.$primary-600};
        }
      }

      &:active {
        color: vars.$primary-press;

        svg {
          --gn-svg-fill: #{vars.$primary-press};
        }
      }
    }

    @include mixins.mobile-only {
      &.current {
        background-color: vars.$primary-100;

        .title,
        p {
          color: vars.$primary-600;
        }

        .arrow-link svg {
          --gn-svg-fill: #{vars.$primary-600};
        }
      }
    }
  }
}

@use '../../styles/mixins' as mixins;
@use '../../styles/variables' as vars;

.title {
  display: none;
  color: vars.$neutral-500;
  @include mixins.typography(body-normal-light);

  a {
    @include mixins.typography(body-normal-medium);
    display: flex;
    align-items: center;
    color: vars.$neutral-500;
    text-decoration: none;
  }

  span {
    margin-right: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;

    a {
      color: vars.$primary-600;
    }

    svg {
      --gn-svg-fill: #{vars.$primary-600};
    }
  }

  &:active {
    a,
    svg {
      color: vars.$primary-press;
    }
  }

  @include mixins.media-lg {
    display: flex;
    align-items: center;
  }
}

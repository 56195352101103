@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.link-stack {
  display: flex;
  @include mixins.typography(body-normal-medium);

  .rows {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: calc(25% - 16px);

    &:not(:first-child) {
      margin-left: 16px;
    }

    li {
      margin-bottom: 8px;

      a {
        @include mixins.typography(body-normal-regular);

        &.plain {
          color: vars.$neutral-500;
          text-decoration: none;
        }

        &.default {
          color: vars.$primary-600;
        }
      }
    }
  }
}

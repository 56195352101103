@use '../../styles/mixins';
@use '../../styles/variables' as vars;

.logo-toggle {
  display: flex;
  align-items: center;
  min-height: vars.$nav-height-mobile;
  padding-left: 24px;

  @include mixins.media-lg {
    padding: 0;
  }

  .toggle {
    display: inline-block;
    text-align: center;
    align-self: center;
    line-height: 0;
    margin-right: 18px;
    background: transparent;

    &:hover,
    &:focus {
      background: transparent;
    }

    @include mixins.media-lg {
      display: none;
    }
  }

  .logo-link {
    line-height: 0;
    box-sizing: border-box;

    svg {
      height: 32px;
      width: 50px;

      @include mixins.media-lg {
        height: 40px;
        width: 64px;
      }
    }
  }
}
